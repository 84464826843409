import { FC, memo } from 'react';

import styles from './Type.scss';

interface TypeProps {
  name: 'interface' | 'type';
}

export const Type: FC<TypeProps> = memo(({ name }) => {
  return <div className={styles.Container}>{name}</div>;
});

Type.displayName = 'Type';
