import { createContext } from 'react';

import { ContentProviderMethods, ContentProviderState } from '../types';

export const contentProviderInitialState: ContentProviderState = {};

export const ContentProviderStateContext = createContext<ContentProviderState>(
  contentProviderInitialState
);

export const ContentProviderMethodsContext = createContext<ContentProviderMethods>({
  addContent: () => {
    // default implementation
  },
  removeContent: () => {
    // default implementation
  }
});
