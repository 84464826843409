import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './Section.scss';

interface SectionProps extends Stylable, Testable {
  children: ReactNode;
}

export const Section: FC<SectionProps> = memo(({ children, className, dataTest }) => {
  return (
    <section
      className={useClass([styles.Container, className], [className])}
      data-test={`section-${dataTest}`}
    >
      {children}
    </section>
  );
});

Section.displayName = 'Section';
