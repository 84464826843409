import { Fragment, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router';

import { LazyHome } from '@pages/home';
import { LazyReactCustomHooks } from '@pages/react-custom-hooks';
import { LazyReactFormBuilder } from '@pages/react-form-builder';
import { LazyFormRootPage } from '@pages/react-form-builder/pages/FormRoot';
import { useTheme } from '@providers';
import { BaseCSS } from '@styles';
import { Loader, Main } from '@ui';

const App = () => {
  const { theme } = useTheme();

  return (
    <Fragment>
      <Helmet>
        <title>rounik</title>
        <meta name="description" content="rounik website" />
        <meta name="theme-color" content="#081229" />
      </Helmet>
      <BaseCSS />
      <Main className={theme} dataTest="main">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<LazyHome />} path="/" />
            <Route element={<LazyReactCustomHooks />} path="react-custom-hooks" />
            <Route element={<LazyReactFormBuilder />} path="react-form-builder">
              <Route element={<LazyFormRootPage />} path="form-root" />
            </Route>
          </Routes>
        </Suspense>
      </Main>
    </Fragment>
  );
};

export default {
  element: App
};
