import { FC, memo, MouseEventHandler, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './ButtonOpaque.scss';

interface ButtonOpaqueProps extends Stylable, Testable {
  ariaLabel: string;
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const ButtonOpaque: FC<ButtonOpaqueProps> = memo(
  ({ ariaLabel, children, className, dataTest, onClick }) => {
    return (
      <button
        aria-label={ariaLabel}
        className={useClass([styles.Container, className], [className])}
        data-test={`button-${dataTest}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
);

ButtonOpaque.displayName = 'ButtonOpaque';
