import { FC, memo, ReactNode, useState } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { GlobalModel, Stylable, Testable } from '@rounik/react-form-builder';

import { ScrollContainer } from '@ui/containers';
import { IconMenu } from '@ui/icons';

import styles from './SideMenu.scss';

interface SideMenuProps extends Stylable, Testable {
  children: ReactNode;
}

export const SideMenu: FC<SideMenuProps> = memo(({ children, className, dataTest }) => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [toggleMenuClasses, setToggleMenuClasses] = useState(
    GlobalModel.classer([styles.ToggleMenu])
  );

  return (
    <aside
      className={useClass(
        [styles.Container, className, menuIsOpened && styles.Opened],
        [className, menuIsOpened]
      )}
      data-test={`side-menu-${dataTest}`}
      onClick={() => {
        setMenuIsOpened(false);
      }}
      onTransitionEnd={() =>
        setToggleMenuClasses(
          GlobalModel.classer([styles.ToggleMenu, menuIsOpened && styles.ToggleMenuHide])
        )
      }
    >
      <button
        aria-label="toggle menu"
        className={toggleMenuClasses}
        onClick={(event) => {
          event.stopPropagation();

          setMenuIsOpened((current) => !current);
        }}
      >
        <IconMenu className={styles.MenuIcon} dataTest={`toggle-${dataTest}`} />
      </button>
      <ScrollContainer className={styles.ScrollContainer}>{children}</ScrollContainer>
    </aside>
  );
});

SideMenu.displayName = 'SideMenu';
