import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import styles from './Name.scss';

interface NameProps {
  children: ReactNode;
  noMargin?: boolean;
}

export const Name: FC<NameProps> = memo(({ children, noMargin }) => {
  return (
    <div className={useClass([styles.Container, noMargin && styles.NoMargin], [noMargin])}>
      {children}
    </div>
  );
});

Name.displayName = 'Name';
