import { FC, memo, ReactNode } from 'react';

import styles from './Group.scss';

interface GroupProps {
  children: ReactNode;
}

export const Group: FC<GroupProps> = memo(({ children }) => {
  return <div className={styles.Container}>{children}</div>;
});

Group.displayName = 'Group';
