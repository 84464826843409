import {
  CSSProperties,
  FC,
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react';

import { useClass, useUnmount } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './Tooltip.scss';

interface TooltipProps extends Stylable, Testable {
  children: ReactNode;
  id: string;
  maxHeight?: number;
  maxWidth?: number;
  onClose?: () => void;
  onOpen?: () => void;
  renderTooltip: () => ReactNode;
  tooltipClassName?: string;
}

const TOOLTIP_OFFSET = 20;

export const Tooltip: FC<TooltipProps> = memo(
  ({
    children,
    className,
    dataTest,
    id,
    maxHeight,
    maxWidth,
    onClose,
    onOpen,
    renderTooltip,
    tooltipClassName
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const [style, setStyle] = useState<CSSProperties>({});

    const close: EventListener = useCallback(
      ({ target }) => {
        if (target !== ref.current && !ref.current?.contains(target as Node)) {
          setStyle((currentStyle) => ({
            ...currentStyle,
            opacity: 0,
            transform: `scale3d(0.1, 0.1, 0.1)`
          }));

          if (onClose) {
            onClose();
          }
        }
      },
      [onClose]
    );

    const open: MouseEventHandler<HTMLDivElement> = useCallback(
      ({ clientX, clientY, target }) => {
        if (
          ref.current !== null &&
          target !== tooltipRef.current &&
          !tooltipRef.current?.contains(target as Node)
        ) {
          let startX = clientX;
          let startY = clientY;

          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;

          let calcMaxWidth: number;
          let calcMaxHeight: number;

          let verticalPosition: 'top' | 'bottom';
          let horizontalPosition: 'left' | 'right';

          if (startX > windowWidth / 2) {
            horizontalPosition = 'right';

            if (maxWidth !== undefined) {
              calcMaxWidth = Math.min(maxWidth, startX);
            } else {
              calcMaxWidth = startX;
            }

            startX = windowWidth - startX;
          } else {
            horizontalPosition = 'left';

            if (maxWidth !== undefined) {
              calcMaxWidth = Math.min(maxWidth, windowWidth - startX);
            } else {
              calcMaxWidth = windowWidth - startX;
            }
          }

          if (startY > windowHeight / 2) {
            verticalPosition = 'bottom';

            if (maxHeight !== undefined) {
              calcMaxHeight = Math.min(maxHeight, startY);
            } else {
              calcMaxHeight = startY;
            }

            startY = windowHeight - startY;
          } else {
            verticalPosition = 'top';

            if (maxHeight !== undefined) {
              calcMaxHeight = Math.min(maxHeight, windowHeight - startY);
            } else {
              calcMaxHeight = windowHeight - startY;
            }
          }

          setStyle(() => ({
            [horizontalPosition]: startX,
            transformOrigin: `${horizontalPosition} ${verticalPosition}`,
            [verticalPosition]: startY,
            ...(calcMaxHeight !== undefined ? { maxHeight: calcMaxHeight - TOOLTIP_OFFSET } : {}),
            ...(calcMaxWidth !== undefined ? { maxWidth: calcMaxWidth - TOOLTIP_OFFSET } : {})
          }));

          if (onOpen) {
            onOpen();
          }

          window.addEventListener('scroll', close, true);
        }
      },
      [onOpen, close, maxWidth, maxHeight]
    );

    useUnmount(() => {
      window.removeEventListener('scroll', close);
    });

    return (
      <div
        aria-describedby={id}
        className={useClass([styles.Container, className], [className])}
        onMouseEnter={open}
        onMouseLeave={() => window.removeEventListener('scroll', close)}
        ref={ref}
      >
        {children}
        <div
          className={useClass([styles.Tooltip, tooltipClassName], [tooltipClassName])}
          data-test={`tooltip-${dataTest}`}
          role="tooltip"
          id={id}
          ref={tooltipRef}
          style={style}
        >
          {renderTooltip()}
        </div>
      </div>
    );
  }
);

Tooltip.displayName = 'Tooltip';
