import { FC, memo } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import styles from './PropertyName.scss';

interface PropertyNameProps {
  generic?: boolean;
  method?: boolean;
  name: string;
}

export const PropertyName: FC<PropertyNameProps> = memo(({ generic, method, name }) => {
  return (
    <div
      className={useClass(
        [styles.Container, generic && styles.Generic, method && styles.Method],
        [generic, method]
      )}
    >
      {name}
    </div>
  );
});

PropertyName.displayName = 'PropertyName';
