import { FC, memo, ReactNode, useMemo, useState } from 'react';

import {
  contentProviderInitialState,
  ContentProviderMethodsContext,
  ContentProviderStateContext
} from './context';
import { ContentProviderMethods, ContentProviderState } from './types';

interface ContentProviderProps {
  children: ReactNode;
}

export const ContentProvider: FC<ContentProviderProps> = memo(({ children }) => {
  const [state, setState] = useState<ContentProviderState>(contentProviderInitialState);

  const methods: ContentProviderMethods = useMemo(
    () => ({
      addContent: ({ item, key }) => {
        setState((currentState) => {
          if (
            currentState[key] &&
            !currentState[key].find((contentItem) => contentItem.id === item.id)
          ) {
            return {
              ...currentState,
              [key]: [...currentState[key], item]
            };
          } else if (!currentState[key]) {
            return {
              ...currentState,
              [key]: [item]
            };
          } else {
            return currentState;
          }
        });
      },
      removeContent: ({ id, key }) => {
        setState((currentState) => {
          if (!currentState[key]) {
            return currentState;
          } else {
            const content = currentState[key];

            const updatedContent = content.filter((contentItem) => contentItem.id !== id);

            return {
              ...currentState,
              [key]: updatedContent
            };
          }
        });
      }
    }),
    []
  );

  return (
    <ContentProviderStateContext.Provider value={state}>
      <ContentProviderMethodsContext.Provider value={methods}>
        {children}
      </ContentProviderMethodsContext.Provider>
    </ContentProviderStateContext.Provider>
  );
});

ContentProvider.displayName = 'ContentProvider';
