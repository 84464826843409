import { FC, forwardRef, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import { Ref } from '@type';

import styles from './Article.scss';

interface ArticleProps extends Stylable, Testable {
  children: ReactNode;
  id: string;
}

export const Article: FC<ArticleProps & Ref> = memo(
  forwardRef(({ children, className, dataTest, id }, ref) => {
    return (
      <article
        className={useClass([styles.Container, className], [className])}
        data-test={`article-${dataTest}`}
        id={id}
        ref={ref}
      >
        {children}
      </article>
    );
  })
);

Article.displayName = 'Article';
