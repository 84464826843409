import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './ContainerLeft.scss';

interface ContainerLeftProps extends Stylable, Testable {
  children: ReactNode;
}

export const ContainerLeft: FC<ContainerLeftProps> = memo(({ children, className, dataTest }) => {
  return (
    <div
      className={useClass([styles.Container, className], [className])}
      data-test={`container-left-${dataTest}`}
    >
      {children}
    </div>
  );
});

ContainerLeft.displayName = 'ContainerLeft';
