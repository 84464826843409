import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import { IconLink } from '@ui/icons';

import styles from './CopyHref.scss';

interface CopyHrefProps extends Stylable, Testable {
  ariaLabel: string;
  to: string;
}

export const CopyHref: FC<CopyHrefProps> = memo(({ ariaLabel, className, dataTest, to }) => {
  return (
    <Link
      aria-label={ariaLabel}
      className={useClass([styles.Container, className], [className])}
      to={to}
      onClick={() => {
        queueMicrotask(() => {
          navigator.clipboard.writeText(document.location.href);
        });
      }}
    >
      <IconLink dataTest={`copy-href-${dataTest}`} opaque />
    </Link>
  );
});

CopyHref.displayName = 'CopyHref';
