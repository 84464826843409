import { FC, memo } from 'react';

import { RounikIconProps } from '@type';

import { SVGIcon } from './components';

export const IconMenu: FC<RounikIconProps> = memo(({ className, dataTest, opaque }) => {
  return (
    <SVGIcon
      className={className}
      dataTest={`menu-${dataTest}`}
      height={24}
      opaque={opaque}
      strokeWidth={2}
      width={24}
    >
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </SVGIcon>
  );
});

IconMenu.displayName = 'IconMenu';
