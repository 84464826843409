import { FC, memo } from 'react';

import styles from './StringLiteral.scss';

interface StringLiteralProps {
  name: string;
}

export const StringLiteral: FC<StringLiteralProps> = memo(({ name }) => {
  return <div className={styles.Container}>{name}</div>;
});

StringLiteral.displayName = 'StringLiteral';
