import { FC, memo } from 'react';

import { RounikIconProps } from '@type';

import { SVGIcon } from './components';

export const IconInfo: FC<RounikIconProps> = memo(({ className, dataTest, opaque }) => {
  return (
    <SVGIcon
      className={className}
      dataTest={`info-${dataTest}`}
      height={24}
      opaque={opaque}
      strokeWidth={2}
      width={24}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </SVGIcon>
  );
});

IconInfo.displayName = 'IconInfo';
