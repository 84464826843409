import { FC, memo, ReactNode, useCallback } from 'react';
import Editor from 'react-simple-code-editor';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import { highlight, languages } from './prism';

import styles from './CodeBlock.scss';

interface CodeBlockProps extends Stylable, Testable {
  code: string;
  language?: string;
}

const onValueChangeHandler = () => {};

export const CodeBlock: FC<CodeBlockProps> = memo(
  ({ className, code, dataTest, language = 'tsx' }) => {
    const highlightHandler: (newCode: string) => ReactNode = useCallback(
      (newCode) => highlight(newCode, languages[language]),
      [language]
    );

    return (
      <div
        className={useClass([styles.Container, className], [className])}
        data-test={`code-block-${dataTest}`}
      >
        <Editor
          value={code}
          onValueChange={onValueChangeHandler}
          highlight={highlightHandler}
          preClassName={styles.EditorContent}
          readOnly
          textareaClassName={styles.EditorContent}
        />
      </div>
    );
  }
);

CodeBlock.displayName = 'CodeBlock';
