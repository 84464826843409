import { FC, memo } from 'react';

import { RounikIconProps } from '@type';

import { SVGIcon } from './components';

export const IconSun: FC<RounikIconProps> = memo(({ className, dataTest, opaque }) => {
  return (
    <SVGIcon
      className={className}
      dataTest={`sun-${dataTest}`}
      height={24}
      opaque={opaque}
      strokeWidth={2}
      width={24}
    >
      <circle cx="12" cy="12" r="5"></circle>
      <line x1="12" y1="1" x2="12" y2="3"></line>
      <line x1="12" y1="21" x2="12" y2="23"></line>
      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
      <line x1="1" y1="12" x2="3" y2="12"></line>
      <line x1="21" y1="12" x2="23" y2="12"></line>
      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
    </SVGIcon>
  );
});

IconSun.displayName = 'IconSun';
