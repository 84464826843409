import { FC, memo, ReactNode, RefObject } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import { IconSearch } from '@ui/icons';

import {
  Option as AutocompleteOptionRFB,
  Autocomplete as AutocompleteRFB,
  AutocompleteProps as AutocompleteRFBProps
} from './components';

import styles from './AutocompleteSearch.scss';

export interface AutocompleteItem {
  id: string;
  label: string;
}

interface AutocompleteRenderOption {
  item: AutocompleteItem;
  ref: RefObject<HTMLLIElement>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AutocompleteValue = any;

interface AutocompleteProps<T>
  extends Omit<AutocompleteRFBProps<T>, 'extractId' | 'extractLabel' | 'renderOption'> {
  extractId?: (item: AutocompleteValue) => AutocompleteValue;
  extractLabel?: (item: T) => string;
  renderOption?: ({}: { item: T; ref: RefObject<HTMLLIElement> }) => ReactNode;
}

const defaultRenderOption = ({ item, ref }: AutocompleteRenderOption) => (
  <AutocompleteOptionRFB
    dataTest={item.id}
    id={item.id}
    key={item.id}
    ref={ref}
    text={item.label}
  />
);

const defaultExtractId = (item: AutocompleteItem) => item?.id ?? '';

const defaultExtractLabel = (item: AutocompleteItem) => item.label;

export const AutocompleteSearch: FC<AutocompleteProps<AutocompleteItem>> = memo(
  ({
    autocomplete = true,
    className,
    extractId = defaultExtractId,
    extractLabel = defaultExtractLabel,
    renderOption = defaultRenderOption,
    ...otherProps
  }) => {
    return (
      <div className={useClass([styles.Container, className], [className])}>
        <IconSearch className={styles.SearchIcon} dataTest="search" />
        <AutocompleteRFB
          autocomplete={autocomplete}
          className={styles.Autocomplete}
          extractId={extractId}
          extractLabel={extractLabel}
          placeholder="Search"
          renderOption={renderOption}
          {...otherProps}
        />
      </div>
    );
  }
);

AutocompleteSearch.displayName = 'AutocompleteSearch';
