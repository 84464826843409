import { FC, memo } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import { SVGIconProps } from '../../types';

import styles from './SVGIcon.scss';

export const SVGIcon: FC<SVGIconProps> = memo(
  ({ children, className, dataTest, height, opaque, strokeWidth, width }) => {
    return (
      <div
        className={useClass(
          [styles.Container, opaque && styles.Opaque, className],
          [className, opaque]
        )}
        data-test={`svg-icon-${dataTest}`}
      >
        <svg
          fill="none"
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          xmlns="http://www.w3.org/2000/svg"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        >
          {children}
        </svg>
      </div>
    );
  }
);

SVGIcon.displayName = 'SVGIcon';
