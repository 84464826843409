import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './ExternalLink.scss';

interface ExternalLinkProps extends Stylable, Testable {
  ariaLabel: string;
  children: ReactNode;
  href: string;
}

export const ExternalLink: FC<ExternalLinkProps> = memo(
  ({ ariaLabel, children, className, dataTest, href }) => {
    return (
      <a
        aria-label={ariaLabel}
        className={useClass([styles.Container, className], [className])}
        data-test={`external-link-${dataTest}`}
        href={href}
        target="_blank"
        title={ariaLabel}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
);

ExternalLink.displayName = 'ExternalLink';
