import { FC, memo, ReactNode } from 'react';

import { PropertyName } from '../PropertyName';
import { Punctuation } from '../Punctuation';
import { Row } from '../Row';

interface NestedObjectProps {
  children: ReactNode;
  propertyName: string;
}

export const NestedObject: FC<NestedObjectProps> = memo(({ children, propertyName }) => {
  return (
    <Row paddingLeft>
      <Row>
        <PropertyName name={propertyName} />
        <Punctuation char=":" marginRight />
        <Punctuation char="{" />
      </Row>
      {children}
      <Row>
        <Punctuation char="}" />
        <Punctuation char=";" />
      </Row>
    </Row>
  );
});

NestedObject.displayName = 'NestedObject';
