import { FC, memo, useMemo, useState } from 'react';

import { ThemeContext } from './context';
import { Theme, ThemeProviderProps, Themes } from './types';

export const ThemeProvider: FC<ThemeProviderProps> = memo(({ children }) => {
  const [theme, setTheme] = useState<Theme>(Themes.Light);

  const contextValue = useMemo(() => {
    return {
      setTheme,
      theme
    };
  }, [theme]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
});

ThemeProvider.displayName = 'ThemeProvider';
