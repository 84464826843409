import { createContext } from 'react';

import { Theme, Themes } from '../types';

interface ThemeContextType {
  setTheme: (theme: Theme) => void;
  theme: Theme;
}

export const ThemeContext = createContext<ThemeContextType>({
  setTheme: () => {},
  theme: Themes.Light
});
