import { FC, memo, ReactNode, UIEventHandler } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import { ScrollContainer } from '@ui/containers/ScrollContainer';

import styles from './ScrollableSection.scss';

interface ScrollableSectionProps extends Stylable, Testable {
  children: ReactNode;
  onScroll?: UIEventHandler;
}

export const ScrollableSection: FC<ScrollableSectionProps> = memo(
  ({ children, className, dataTest, onScroll }) => {
    return (
      <section
        className={useClass([styles.Container, className], [className])}
        data-test={`scrollable-section-${dataTest}`}
      >
        <ScrollContainer onScroll={onScroll}>{children}</ScrollContainer>
      </section>
    );
  }
);

ScrollableSection.displayName = 'ScrollableSection';
