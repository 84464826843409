import { ReactNode } from 'react';

export enum Themes {
  Dark = 'Dark',
  Light = 'Light'
}

export type Theme = keyof typeof Themes;

export interface ThemeProviderProps {
  children: ReactNode;
}
