import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './ArticleHeader.scss';

interface ArticleHeaderProps extends Stylable, Testable {
  children: ReactNode;
}

export const ArticleHeader: FC<ArticleHeaderProps> = memo(({ children, className, dataTest }) => {
  return (
    <div
      className={useClass([styles.Container, className], [className])}
      data-test={`article-header-${dataTest}`}
    >
      {children}
    </div>
  );
});

ArticleHeader.displayName = 'ArticleHeader';
