// Config for scroll direction:

interface Container {
  dimension: 'height' | 'width';
  offsetDimension: 'offsetHeight' | 'offsetWidth';
  scrollDimension: 'scrollHeight' | 'scrollWidth';
  scrollSide: 'scrollLeft' | 'scrollTop';
}

interface Oveflow {
  x: 'hidden' | 'scroll';
  y: 'hidden' | 'scroll';
}

type PageCoordinate = 'pageX' | 'pageY';

interface ScrollBar {
  clientDimension: 'clientHeight' | 'clientWidth';
  offsetDimension: 'offsetHeight' | 'offsetWidth';
}

type ScrollDimension = 'height' | 'width';

interface Scroller {
  clientDimension: 'clientHeight' | 'clientWidth';
  offsetDimension: 'offsetHeight' | 'offsetWidth';
}

interface Wrapper {
  clientDimension: 'clientHeight' | 'clientWidth';
  offsetSide: 'offsetLeft' | 'offsetTop';
  overflowDimension: 'height' | 'width';
}

interface DirectionConfigItem {
  container: Container;
  overflow: Oveflow;
  pageCoordinate: PageCoordinate;
  scrollDimension: ScrollDimension;
  scrollbar: ScrollBar;
  scroller: Scroller;
  wrapper: Wrapper;
}

interface DirectionConfig {
  horizontal: DirectionConfigItem;
  vertical: DirectionConfigItem;
}

export const DIRECTION_CONFIG: DirectionConfig = {
  horizontal: {
    container: {
      dimension: 'height',
      offsetDimension: 'offsetWidth',
      scrollDimension: 'scrollWidth',
      scrollSide: 'scrollLeft'
    },
    overflow: {
      x: 'scroll',
      y: 'hidden'
    },
    pageCoordinate: 'pageX',
    scrollbar: {
      clientDimension: 'clientHeight',
      offsetDimension: 'offsetHeight'
    },
    scrollDimension: 'width',
    scroller: {
      clientDimension: 'clientWidth',
      offsetDimension: 'offsetWidth'
    },
    wrapper: {
      clientDimension: 'clientWidth',
      offsetSide: 'offsetLeft',
      overflowDimension: 'height'
    }
  },
  vertical: {
    container: {
      dimension: 'width',
      offsetDimension: 'offsetHeight',
      scrollDimension: 'scrollHeight',
      scrollSide: 'scrollTop'
    },
    overflow: {
      x: 'hidden',
      y: 'scroll'
    },
    pageCoordinate: 'pageY',
    scrollbar: {
      clientDimension: 'clientWidth',
      offsetDimension: 'offsetWidth'
    },
    scrollDimension: 'height',
    scroller: {
      clientDimension: 'clientHeight',
      offsetDimension: 'offsetHeight'
    },
    wrapper: {
      clientDimension: 'clientHeight',
      offsetSide: 'offsetTop',
      overflowDimension: 'width'
    }
  }
};
