import { FC, memo } from 'react';

import { RounikIconProps } from '@type';

import { SVGIcon } from './components';

export const IconChevronLeft: FC<RounikIconProps> = memo(({ className, dataTest, opaque }) => {
  return (
    <SVGIcon
      className={className}
      dataTest={`chevron-left-${dataTest}`}
      height={24}
      opaque={opaque}
      strokeWidth={2}
      width={24}
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </SVGIcon>
  );
});

IconChevronLeft.displayName = 'IconChevronLeft';
