import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import styles from './Row.scss';

interface RowProps {
  children: ReactNode;
  paddingLeft?: boolean;
}

export const Row: FC<RowProps> = memo(({ children, paddingLeft }) => {
  return (
    <div className={useClass([styles.Container, paddingLeft && styles.PaddingLeft], [paddingLeft])}>
      {children}
    </div>
  );
});

Row.displayName = 'Row';
