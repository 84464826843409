import { FC, memo, ReactNode } from 'react';

import styles from './Definition.scss';

interface DefinitionProps {
  children: ReactNode;
}

export const Definition: FC<DefinitionProps> = memo(({ children }) => {
  return <div className={styles.Container}>{children}</div>;
});

Definition.displayName = 'Definition';
