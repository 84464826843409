import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './Subtitle.scss';

interface SubtitleProps extends Stylable, Testable {
  children: ReactNode;
}

export const Subtitle: FC<SubtitleProps> = memo(({ children, className, dataTest }) => {
  return (
    <h3
      className={useClass([styles.Container, className], [className])}
      data-test={`subtitle-${dataTest}`}
    >
      {children}
    </h3>
  );
});

Subtitle.displayName = 'Subtitle';
