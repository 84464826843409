import { FC, memo } from 'react';

import { useClass } from '@rounik/react-custom-hooks';

import styles from './Punctuation.scss';

interface PunctuationProps {
  char: ':' | ';' | '(' | ')' | '{' | '}' | '=' | '>' | '<' | '[' | ']' | ',' | '|';
  marginLeft?: boolean;
  marginRight?: boolean;
}

export const Punctuation: FC<PunctuationProps> = memo(({ char, marginLeft, marginRight }) => {
  return (
    <div
      className={useClass(
        [styles.Container, marginLeft && styles.MarginLeft, marginRight && styles.MarginRight],
        [marginLeft, marginRight]
      )}
    >
      {char}
    </div>
  );
});

Punctuation.displayName = 'Punctuation';
