import { FC, memo, ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable } from '@rounik/react-form-builder';

import styles from './Link.scss';

interface LinkProps extends Stylable {
  ariaLabel?: string;
  children: ReactNode;
  selected?: boolean;
  to: string;
}

export const Link: FC<LinkProps> = memo(({ ariaLabel, children, className, selected, to }) => {
  return (
    <ReactLink
      aria-label={ariaLabel}
      className={useClass(
        [styles.Container, selected && styles.Selected, className],
        [className, selected]
      )}
      to={to}
    >
      {children}
    </ReactLink>
  );
});

Link.displayName = 'Link';
