import { FC, memo } from 'react';

import { RounikIconProps } from '@type';

import { SVGIcon } from './components';

export const IconMoon: FC<RounikIconProps> = memo(({ className, dataTest, opaque }) => {
  return (
    <SVGIcon
      className={className}
      dataTest={`moon-${dataTest}`}
      height={24}
      opaque={opaque}
      strokeWidth={2}
      width={24}
    >
      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
    </SVGIcon>
  );
});

IconMoon.displayName = 'IconMoon';
