import { FC, memo, MouseEventHandler, ReactNode } from 'react';

import { ButtonOpaque } from '@ui/buttons';

import styles from './TypeReference.scss';

interface TypeReferenceProps {
  ariaLabel: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const defaultOnClick = () => {};

export const TypeReference: FC<TypeReferenceProps> = memo(
  ({ ariaLabel, children, onClick = defaultOnClick }) => {
    return (
      <ButtonOpaque
        ariaLabel={ariaLabel}
        className={styles.Container}
        dataTest="type-builder-type-reference"
        onClick={onClick}
      >
        {children}
      </ButtonOpaque>
    );
  }
);

TypeReference.displayName = 'TypeReference';
