import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './Main.scss';

interface MainProps extends Stylable, Testable {
  children: ReactNode;
}

export const Main: FC<MainProps> = memo(({ children, className, dataTest }) => {
  return (
    <main
      className={useClass([styles.Container, className], [className])}
      data-test={`main-${dataTest}`}
    >
      {children}
    </main>
  );
});

Main.displayName = 'Main';
