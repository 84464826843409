import { FC, memo } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import {
  Accordion as AccordionRFB,
  AccordionProps as AccordionRFBProps
} from '@rounik/react-form-builder';

import styles from './Accordion.scss';

type AccordionProps = AccordionRFBProps;

export const Accordion: FC<AccordionProps> = memo(({ children, className, ...otherProps }) => {
  return (
    <AccordionRFB className={useClass([styles.Container, className], [className])} {...otherProps}>
      {children}
    </AccordionRFB>
  );
});

Accordion.displayName = 'Accordion';
