import { FC, memo, ReactNode } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable, Testable } from '@rounik/react-form-builder';

import styles from './Paragraph.scss';

interface ParagraphProps extends Stylable, Testable {
  children: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({ children, className, dataTest }) => {
  return (
    <p
      className={useClass([styles.Container, className], [className])}
      data-test={`paragraph-${dataTest}`}
    >
      {children}
    </p>
  );
});

Paragraph.displayName = 'Paragraph';
