import { FC, memo, ReactNode, UIEventHandler } from 'react';

import { useClass } from '@rounik/react-custom-hooks';
import { Stylable } from '@rounik/react-form-builder';

import { Scroll, ScrollConfig } from '@ui/Scroll';

import styles from './ScrollContainer.scss';

const SCROLL_CONFIG: ScrollConfig = {
  containerClass: styles.ScrollContainer,
  direction: 'vertical',
  minScrollerSize: 40,
  resizeDebounce: 300,
  scrollerClass: styles.Scroller,
  scrollerMovingClass: styles.ScrollerMoving,
  track: true,
  trackClass: styles.ScrollTrack,
  trackShift: 300
};

export interface ScrollContainerProps extends Stylable {
  children: ReactNode;
  height?: string;
  onScroll?: UIEventHandler;
}

export const ScrollContainer: FC<ScrollContainerProps> = memo(
  ({ children, className, height, onScroll }) => {
    return (
      <Scroll
        {...SCROLL_CONFIG}
        className={useClass([styles.ScrollWrap, className], [className])}
        height={height}
        onScroll={onScroll}
      >
        {children}
      </Scroll>
    );
  }
);

ScrollContainer.displayName = 'ScrollContainer';
